import React from 'react';

import { Link } from 'gatsby';

import Footer from '../components/Footer';
import Seo from '../components/Seo';

import '../styles/index.scss';

const UserExclusionPage = () => {
  return (
    <>
      <Seo title="Solicitar Exclusão da Conta">
        <meta name="robots" content="noindex, nofollow" />
      </Seo>
      <header className="i-header">
        <nav className="i-header__nav">
          <ul>
            <li>
              <Link
                className="eye-link"
                style={{ color: '#b635f6 ' }}
                title="Home"
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="eye-button"
                title="Aproveitar Oferta"
                to="/#ofertas"
              >
                Aproveitar Oferta
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <main role="main" className="i-page">
        <section className="i-container">
          <div
            className="i-container__text"
            style={{ paddingTop: 150, paddingBottom: 150 }}
          >
            <h1>Solicitar Exclusão da Conta</h1>
            <p>Prezado(a) Usuário(a),</p>
            <p>
              Entendemos que você deseja ter mais controle sobre seus dados
              pessoais e estamos aqui para ajudá-lo(a). Seguindo as políticas da
              Play Store, criamos este portal para que você possa solicitar a
              exclusão de seus dados coletados pelo aplicativo Eye Essencial.
              Antes de solicitar a exclusão, lembre-se:
            </p>
            <ul>
              <li>
                A exclusão de seus dados não significa que sua conta do Google
                será excluída.
              </li>
              <li>
                Nem todos os dados podem ser excluídos, pois alguns são
                necessários para o funcionamento do aplicativo.
              </li>
              <li>
                Após a exclusão, você não poderá mais utilizar o aplicativo Eye
                Essencial.
              </li>
            </ul>
            <p>Para solicitar a exclusão de seus dados:</p>
            <ol>
              <li>
                Acesse o portal do Eye Essencial:{' '}
                <a href="http://web.eye.com.br">http://web.eye.com.br</a>
              </li>
              <li>Faça login em sua conta.</li>
              <li>
                No menu principal, selecione &quot;Privacidade e
                Segurança&quot;.
              </li>
              <li>Clique em &quot;Solicitar Excluir Dados&quot;.</li>
              <li>
                Siga as instruções na tela para confirmar sua solicitação.
              </li>
            </ol>
            <p>
              Seu pedido será processado dentro de 30 dias. Você receberá um
              e-mail de confirmação quando a exclusão for concluída. Observações
              importantes:
            </p>
            <ul>
              <li>
                Se você tiver dúvidas sobre a exclusão de seus dados, entre em
                contato com nosso suporte através do e-mail
                suporte@eyemobile.com.br.
              </li>
              <li>
                Para mais informações sobre a política de dados do Eye
                Essencial, consulte nossa Política de Privacidade: link para a
                política de privacidade.
              </li>
            </ul>
            <p>
              <strong>Agradecemos sua compreensão.</strong>
            </p>
            <p>
              <strong>Equipe Eye Essencial</strong>
            </p>
            <p>Informações adicionais:</p>
            <ul>
              <li>
                A Play Store exige que os desenvolvedores ofereçam aos usuários
                uma maneira de solicitar a exclusão de seus dados.
              </li>
              <li>
                O aplicativo Eye Essencial está em conformidade com esta
                política.
              </li>
              <li>
                Esta página fornece aos usuários as informações e os recursos
                necessários para solicitar a exclusão de seus dados.
              </li>
            </ul>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default UserExclusionPage;
